<template>
  <v-dialog v-model="dialogState" height="100%" persistent scrollable max-width="500px">
    <v-form ref="form" v-model="validForm" lazy-validation>
      <v-card>
        <v-card-title class="text-subtitle-1 grey lighten-2 text-uppercase d-flex justify-space-between">
          <div v-if="formType === 'add'">New Account</div>
          <div v-else>Edit Account</div>
          <v-btn icon @click="closeForm">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text class="mt-3 px-5 pt-2">
          <v-row>
            <v-col cols="12" class="py-1 mt-2">
              <v-text-field
                  dense
                  v-model="form.name"
                  label="Account Name"
                  outlined
                  :rules="[getRules.required]"
                  :error-messages="getAccountErrors.name"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions class="px-5 py-3 elevation-4">
          <v-btn :loading="loading" block color="primary" @click="validate">
            <span v-if="formType === 'add'">
              <v-icon class="mr-2">mdi-plus</v-icon>
            Add Account
            </span>
            <span v-else>
              <v-icon class="mr-2">mdi-pencil</v-icon>
            Update Account
            </span>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>

import {mapGetters, mapMutations, mapActions} from 'vuex';

export default {
  name: "OrganizationForm",
  props: ['formType','dialogState'],
  data: () => ({
    validForm: true,
    form: new Form(),
    selectedColor: null,
    colorMenu: false,
    loading: false,
    x: 0,
    y: 0,
  }),
  mounted() {
    this.initForm();
  },
  computed: {
    ...mapGetters(['getRules', 'getAuthUser', 'getAccount', 'getAccountErrors', 'getAccountAddStatus', 'getAccountUpdateStatus'])
  },
  watch: {
    getAccount() {
      this.initForm();
    }
  },
  methods: {
    initForm() {
      if (this.$refs.form) this.$refs.form.resetValidation();
      if (this.formType === 'edit') {
        this.form = new Form(this.getAccount);
        this.selectedColor = this.form.color;
      }
      else{
        this.form = new Form();
      }
    },
    selectColor() {
      this.form.color = this.selectedColor;
      this.colorMenu = false;
    },
    show(e) {
      e.preventDefault()
      this.colorMenu = false
      this.x = e.clientX
      this.y = e.clientY
      this.$nextTick(() => {
        this.colorMenu = true
      })
    },
    closeForm() {
      this.resetAccountErrors();
      this.$refs.form.resetValidation();
      this.$refs.form.reset();
      this.$emit('close-form', true)
    },
    showMessage(type="add") {
      this.$emit('successMessage',type)
    },
    validate() {
      if (this.$refs.form.validate()) {
        this.submit();
      }
    },
    async submit() {
      this.form.organization_id = this.getAccount.id;
      let organization = Object.assign({}, this.form);
      this.loading = true;
      if (this.formType === 'add') {
        organization.organization_type = 'seller';
        await this.addAccount(organization);
      } else {
        this.setAccount(organization);
        await this.updateAccount(organization);
      }
      this.loading = false;
      if ( this.getAccountAddStatus === 2 || this.getAccountUpdateStatus === 2 ) {
        this.loadAccounts({mlforceload:true, mlsilent:true});
        this.closeForm();
        this.showMessage(this.formType);
      }
    },
    ...mapMutations(['setAccount', 'resetAccountErrors']),
    ...mapActions(['updateAccount', 'addAccount', 'loadAccounts'])
  }
}
</script>

<style scoped>

</style>